/* eslint-disable class-methods-use-this */
import { ApolloLink } from '@apollo/client';

class CustomHeadersMiddleware extends ApolloLink {
    request(operation, next) {
        const newHeaders = {
            // https://jira.sephora.com/browse/INFL-5628
            'x-api-key': Sephora.configurationSettings.sdnUfeAPIUserKey
        };
        // This object ^^^  should be used to define headers used for every request.
        // Utils like location.isBasketPage() can be used for custon logic implementation.
        operation.setContext(({ headers = {} }) => ({
            headers: {
                ...headers,
                ...newHeaders
            }
        }));

        return next(operation);
    }
}

export default CustomHeadersMiddleware;
