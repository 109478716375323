/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { wrapComponent } from 'utils/framework';
import BaseClass from 'components/BaseClass';
import RmnEvents from 'analytics/utils/rmnEvents';
import { RmnBannerStyle } from 'components/Rmn/RmnBanner';
import withRmnBanners from 'viewModel/rmn/rmnBanner/withRmnBanner';

/**
 * RmnBanner component
 * @param slot {string} - The slot name for the banner
 * Definitions:
 * - DESKTOP
 * - 2502124 - Siderall Desktop / RMN_BANNER_TYPES.TYPES.WIDE_SIDESCRAPER.NAME
 */
class RmnSideBanner extends BaseClass {
    constructor(props) {
        super(props);
        this.state = {
            showSkeleton: true
        };

        this.tracker = new RmnEvents();
        this.bannerRef = React.createRef();
    }

    componentDidMount() {
        if (Sephora.configurationSettings.RMNEnableDisplay) {
            const { bannerData } = this.props;

            if (bannerData) {
                this.setState({ bannerData, showSkeleton: false });
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (Sephora.configurationSettings.RMNEnableDisplay) {
            const { bannerData } = this.props;

            if (prevProps.bannerData !== bannerData) {
                this.setState({ bannerData, showSkeleton: false });
            }

            if (this.bannerRef?.current && !this.tracker.isInitialized()) {
                this.tracker.observe(this.bannerRef.current);

                this.tracker.setCallback(this.props.triggerImpression);
            }
        }
    }

    componentWillUnmount() {
        this.tracker.destroy();
    }

    render() {
        const { bannerData, showSkeleton } = this.state;
        const { bannerProps } = this.props;

        return (
            <RmnBannerStyle
                bannerData={bannerData}
                showSkeleton={showSkeleton}
                ref={this.bannerRef}
                {...bannerProps}
            />
        );
    }
}

RmnSideBanner.propTypes = {
    marginTop: PropTypes.number,
    marginBottom: PropTypes.number,
    isCentered: PropTypes.bool,
    hasFallback: PropTypes.bool,
    showSkeleton: PropTypes.bool,
    isSearchPage: PropTypes.bool,
    node: PropTypes.string,
    keyword: PropTypes.string
};

RmnSideBanner.defaultProps = {
    marginTop: null,
    marginBottom: null,
    isCentered: false,
    hasFallback: false,
    showSkeleton: false,
    rmnObj: { impressionFired: false }
};

const Banner = wrapComponent(RmnSideBanner, 'RmnSideBanner', true);

export default withRmnBanners(Banner, 'side');
