import React from 'react';
import PropTypes from 'prop-types';
import { wrapComponent } from 'utils/framework';
import BaseClass from 'components/BaseClass';

import {
    radii, colors, fontSizes, space
} from 'style/config';

import AccountGreeting from 'components/Header/AccountGreeting';
import {
    Box, Flex, Link, Image, Divider, Text, Icon
} from 'components/ui';
import Markdown from 'components/Markdown/Markdown';
import Dropdown from 'components/Dropdown/Dropdown';
import Avatar from 'components/Avatar';
import MyOffersLink from 'components/MyOffers/MyOffersLink';

/* utils */
import userUtils from 'utils/User';
import localeUtils from 'utils/LanguageLocale';
import anaUtils from 'analytics/utils';
import authUtils from 'utils/Authentication';
import Location from 'utils/Location';

import anaConsts from 'analytics/constants';
import store from 'store/Store';
import { HydrationFinished } from 'constants/events';

import { APPROVAL_STATUS } from 'constants/CreditCard';

import NotificationDot from 'components/NotificationDot';
import Badge from 'components/Badge';
import { HEADER_VALUE } from 'constants/authentication';
import helpersUtils from 'utils/Helpers';

const { deferTaskExecution } = helpersUtils;

const {
    PAGE_NAMES: {
        BEAUTY_PREFERENCES,
        BEAUTY_CHALLENGES,
        BEAUTY_INSIDER,
        REWARDS_BAZAAR,
        CREDIT_CARD_SECTION_JOIN,
        PURCHASE_HISTORY,
        ORDERS,
        AUTO_REPLENISH,
        LOVES,
        ACCOUNT_SETTINGS,
        SAME_DAY_UNLIMITED_TRACK,
        BEAUTY_ADVISOR_RECOMMENDATIONS
    }
} = anaConsts;

const LINK_PROPS = {
    display: 'flex',
    paddingY: 3,
    paddingX: 4,
    width: '100%',
    hoverSelector: '.Link-target'
};

const ICON_PROPS = {
    size: 24,
    marginRight: 3,
    marginTop: '2px'
};

const BUY_IT_AGAIN_IMAGE_PROPS = {
    size: 48,
    marginRight: 3,
    marginTop: '8px'
};

class AccountMenu extends BaseClass {
    state = {
        isDropOpen: false,
        isAnonymous: null,
        user: {},
        isUserReady: false,
        isBirthdayGiftEligible: null,
        creditCardInfo: null,
        isScrollActive: false,
        showBuyItAgainImages: Sephora.isMobile() && !userUtils.isAnonymous(),
        purchaseHistory: {},
        hasFetchedPurchaseHistory: false,
        biPersonalizedOffers: {}
    };

    scrollRef = React.createRef();

    joinBi = e => {
        e.preventDefault();
        this.props.showBiRegisterModal({
            isOpen: true,
            callback: this.props.onDismiss ? this.props.onDismiss : null
        });
    };

    signIn = () => {
        const navInfo = anaUtils.buildNavPath(['top nav', 'account', 'sign-in']);
        deferTaskExecution(() => {
            this.props.showSignInModal({
                isOpen: true,
                analyticsData: { navigationInfo: navInfo },
                source: authUtils.SIGN_IN_SOURCES.ACCOUNT_GREETING,
                extraParams: { headerValue: HEADER_VALUE.USER_CLICK }
            });
        });
    };

    handleDropdownTrigger = (e, isDropdownOpen) => {
        if (this.scrollRef && this.scrollRef.current) {
            this.scrollRef.current.scrollTop = 0;
        }

        this.setState({ isDropOpen: isDropdownOpen });
    };

    handleTriggerClick = () => {
        if (this.state.isAnonymous) {
            this.signIn();
        }
    };

    /* eslint-disable-next-line complexity */
    render() {
        const {
            isAnonymous, isDropOpen, isScrollActive, showBuyItAgainImages, isUserReady
        } = this.state;

        const { user, localization, showBlackSearchHeader } = this.props;
        const beautyInsiderAccount = user && user.beautyInsiderAccount;
        const biPoints = (beautyInsiderAccount && beautyInsiderAccount.promotionPoints) || 0;
        const biStatus = beautyInsiderAccount && userUtils.displayBiStatus(beautyInsiderAccount.vibSegment);
        const userFirstName = user?.firstName ?? beautyInsiderAccount?.firstName;
        const userLastName = user?.lastName ?? beautyInsiderAccount?.lastName;

        const menu = this.renderMenu({
            biPoints,
            biStatus,
            showBuyItAgainImages
        });

        const logoSrc = beautyInsiderAccount && `/img/ufe/bi/logo-${biStatus.toLowerCase()}${showBlackSearchHeader ? '-white' : ''}.svg`;
        const dropdownStyle = !isUserReady ? { visibility: 'hidden' } : { visibility: 'visible' };
        const avatarStyle = !isDropOpen && isAnonymous && !showBlackSearchHeader ? { opacity: 0.6 } : null;
        const spanStyle = isDropOpen ? { boxShadow: `0 2px 0 0 ${showBlackSearchHeader ? colors.white : colors.black}` } : null;
        const boxStyle = isScrollActive ? { borderBottom: `1px solid ${colors.lightGray}` } : null;

        if (!this.props.dropWidth) {
            return menu;
        } else {
            return (
                <Dropdown
                    id='account_drop'
                    hasCustomScroll={true}
                    hasMaxHeight={true}
                    height='100%'
                    style={dropdownStyle}
                    onTrigger={this.handleDropdownTrigger}
                >
                    <Dropdown.Trigger
                        display='flex'
                        alignItems='center'
                        paddingX={4}
                        lineHeight='tight'
                        height='100%'
                        minWidth='13em'
                        onClick={this.handleTriggerClick}
                    >
                        <Avatar
                            {...(isAnonymous && { src: `/img/ufe/icons/me${isDropOpen || showBlackSearchHeader ? '-active' : '32'}.svg` })}
                            marginY={-1}
                            marginRight={3}
                            size={32}
                            isOutlined={isDropOpen}
                            style={avatarStyle}
                        />
                        <span style={spanStyle}>
                            {user && userFirstName ? (
                                <>
                                    {localization.greeting},{' '}
                                    {userFirstName.length > 8 ? `${userFirstName.charAt(0)}.${userLastName.charAt(0)}.` : userFirstName}
                                    <NotificationDot
                                        marginTop='-1px'
                                        marginLeft='1px'
                                    />
                                    {beautyInsiderAccount ? (
                                        <Text
                                            fontSize='sm'
                                            display='block'
                                        >
                                            <Image
                                                src={logoSrc}
                                                alt={biStatus}
                                                height='.725em'
                                            />{' '}
                                            <strong>{biPoints.toLocaleString()}</strong> pt{biPoints !== 1 ? 's' : ''}
                                        </Text>
                                    ) : null}
                                </>
                            ) : (
                                <>
                                    <Text
                                        fontSize='sm'
                                        fontWeight='bold'
                                        display='block'
                                        data-at={Sephora.debug.dataAt('sign_in_header')}
                                    >
                                        {localization.signIn}
                                        <NotificationDot
                                            marginTop='-1px'
                                            marginLeft='1px'
                                        />
                                    </Text>
                                    <Text
                                        fontSize='xs'
                                        display='block'
                                    >
                                        {localization.signInPrompt} 🚚
                                    </Text>
                                </>
                            )}
                        </span>
                    </Dropdown.Trigger>
                    <Dropdown.Menu
                        width={this.props.dropWidth}
                        align='center'
                        paddingTop={4}
                    >
                        <Box
                            paddingX={4}
                            paddingBottom={2}
                            style={boxStyle}
                        >
                            <AccountGreeting />
                        </Box>
                        <Box
                            paddingTop={1}
                            paddingBottom={4}
                            lineHeight='tight'
                            overflowY='auto'
                            onScroll={this.handleScroll}
                            ref={this.scrollRef}
                        >
                            {menu}
                        </Box>
                    </Dropdown.Menu>
                </Dropdown>
            );
        }
    }

    handleScroll = () => {
        const refData = this.scrollRef && this.scrollRef.current;
        const { isScrollActive } = this.state;

        if (refData && refData.scrollTop > 0) {
            !isScrollActive &&
                this.setState({
                    isScrollActive: true
                });
        } else {
            isScrollActive &&
                this.setState({
                    isScrollActive: false
                });
        }
    };

    handleBeautyPreferencesClick = () => {
        this.trackNavClick(BEAUTY_PREFERENCES);
    };

    handleBeautyInsiderClick = () => {
        this.trackNavClick(BEAUTY_INSIDER);
    };

    handleRewardsBazaarClick = () => {
        this.trackNavClick(REWARDS_BAZAAR);
    };

    handleCreditCardClick = () => {
        this.trackNavClick(CREDIT_CARD_SECTION_JOIN);
    };

    handleBeautyChallengesClick = e => {
        this.trackNavClick(BEAUTY_CHALLENGES);
        Location.navigateTo(e, '/beauty/challenges');
        this.props.onDismiss();
    };

    handlePurchaseHistoryClick = () => {
        this.trackNavClick(PURCHASE_HISTORY);
    };

    handelOrdersClick = () => {
        this.trackNavClick(ORDERS);
    };

    handleAutoReplenishClick = () => {
        this.trackNavClick(AUTO_REPLENISH);
    };

    handleLovesClick = () => {
        this.trackNavClick(LOVES);
    };

    handleAccountSettingsClick = () => {
        this.trackNavClick(ACCOUNT_SETTINGS);
    };

    handleSameDayUnlimitedClick = () => {
        this.trackNavClick(SAME_DAY_UNLIMITED_TRACK);
    };

    handleBeautyAdvisorRecommendationsClick = () => {
        this.trackNavClick(BEAUTY_ADVISOR_RECOMMENDATIONS);
    };

    /* eslint-disable-next-line complexity */
    renderMenu = ({ biPoints, biStatus, showBuyItAgainImages }) => {
        const { isAnonymous, creditCardInfo, isBirthdayGiftEligible, biPersonalizedOffers } = this.state;
        const { user, localization, profileCompletionPercentage } = this.props;
        const { biCashLockUpMsg, beautyInsiderAccount, ccRewards } = user;
        const { bankRewards } = ccRewards ?? {};
        const ccRewardsAmount = userUtils.getRewardsAmount(bankRewards);
        const isUserSignedInRecognized = userUtils.isSignedIn() || userUtils.isRecognized();
        const sameDayUnlimitedSubscription = user.userSubscriptions?.filter(
            subscription => subscription.type === 'SDU' && subscription.status === 'ACTIVE'
        );
        const isSDUMember = isUserSignedInRecognized && sameDayUnlimitedSubscription?.length > 0;
        const isSDUNonMember = isUserSignedInRecognized && sameDayUnlimitedSubscription?.length === 0;
        const SDUUrl = isSDUMember ? '/profile/MyAccount/SameDayUnlimited' : '/product/subscription-same-day-unlimited-P483900';
        const isMyOffersModuleEnabled = Sephora?.configurationSettings?.isMyOffersModuleEnabled;
        const hasBINotifications = isBirthdayGiftEligible || biCashLockUpMsg || isMyOffersModuleEnabled;
        const hasAutoReplenishSubscriptions =
            Array.isArray(user.subscriptionSummary) &&
            user.subscriptionSummary?.some(subscription => subscription.type === 'REPLENISHMENT' && (subscription.active || subscription.paused));
        const showAutoReplenishDescWithSubs = (isUserSignedInRecognized && hasAutoReplenishSubscriptions) || false;
        const isAutoReplenishEmptyHubEnabled = Sephora.configurationSettings.isAutoReplenishEmptyHubEnabled || false;

        const progressBarStyle = {
            width: `${profileCompletionPercentage}%`
        };
        const descStyle = {
            marginTop: '.375em'
        };
        const emptyStyle = {
            '&:empty': { display: 'none' }
        };

        return this.state.isUserReady ? (
            <>
                <Divider />
                <Link
                    href='/profile/BeautyPreferences'
                    onClick={this.handleBeautyPreferencesClick}
                    {...LINK_PROPS}
                >
                    <div css={styles.linkInner}>
                        <Flex alignItems='center'>
                            <Image
                                src='/img/ufe/icons/beauty-traits.svg'
                                size={24}
                                marginRight={2}
                            />
                            <div
                                className='Link-target'
                                children={localization.beautyPrefHeading}
                            />
                            <Box
                                backgroundColor='lightGray'
                                borderRadius={2}
                                overflow='hidden'
                                height={9}
                                width='36.75%'
                                marginLeft='auto'
                            >
                                <Box
                                    backgroundColor='green'
                                    height='100%'
                                    style={progressBarStyle}
                                />
                            </Box>
                        </Flex>
                        <div css={[styles.desc, descStyle]}>
                            {Sephora.configurationSettings.enableGuidedSelling
                                ? localization.guidedSellingBeautyPrefDesc
                                : localization.beautyPrefDesc}
                        </div>
                    </div>
                </Link>
                <Divider />
                {beautyInsiderAccount ? (
                    <Link
                        href='/profile/BeautyInsider'
                        onClick={this.handleBeautyInsiderClick}
                        {...LINK_PROPS}
                    >
                        <div
                            css={styles.linkInner}
                            data-at={Sephora.debug.dataAt('rb_link_text')}
                        >
                            <div className='Link-target'>{localization.biHeading}</div>
                            <div
                                css={styles.desc}
                                className='Link-target'
                            >
                                {localization.biDesc}
                            </div>
                            {isBirthdayGiftEligible && (
                                <div
                                    css={styles.pill}
                                    data-at={Sephora.debug.dataAt('rb_birth_text')}
                                >
                                    {localization.chooseBirthdayGift}
                                </div>
                            )}
                            {biCashLockUpMsg && (
                                <div css={styles.pill}>
                                    <Markdown
                                        content={biCashLockUpMsg.replace(/\*{2}/g, '*')}
                                        data-at={Sephora.debug.dataAt('cbr_message')}
                                    />
                                </div>
                            )}
                            {isMyOffersModuleEnabled && (
                                <Box
                                    marginTop={1}
                                    fontSize='sm'
                                    css={emptyStyle}
                                >
                                    <MyOffersLink
                                        onDismiss={this.props.onDismiss}
                                        personalizedOffer={biPersonalizedOffers}
                                        data-at={Sephora.debug.dataAt('view_exclusive_offers_link')}
                                    />
                                </Box>
                            )}
                        </div>
                        <Image
                            display='inline-block'
                            data-at={Sephora.debug.dataAt('bi_logo')}
                            src={`/img/ufe/bi/logo-${biStatus.toLowerCase()}.svg`}
                            alt={biStatus}
                            height={12}
                            {...(!hasBINotifications && { marginY: 'auto' })}
                            {...(hasBINotifications && { marginTop: 3 })}
                        />
                    </Link>
                ) : (
                    <Link
                        href='/profile/BeautyInsider'
                        onClick={!isAnonymous ? this.joinBi : null}
                        {...LINK_PROPS}
                    >
                        <div
                            css={styles.linkInner}
                            data-at={Sephora.debug.dataAt('rb_link_text')}
                        >
                            <div className='Link-target'>{localization.biHeading}</div>
                            <div
                                css={styles.desc}
                                className={isAnonymous ? 'Link-target' : null}
                            >
                                {localization.biDesc}
                            </div>
                        </div>
                        {isAnonymous ? (
                            <Image
                                display='block'
                                src='/img/ufe/bi/logo-insider.svg'
                                alt='Beauty Insider'
                                height={12}
                                marginY='auto'
                            />
                        ) : (
                            <Text
                                color='blue'
                                fontSize='sm'
                                className='Link-target'
                            >
                                {localization.joinNow}
                            </Text>
                        )}
                    </Link>
                )}
                <Divider />
                <Link
                    href='/rewards'
                    onClick={this.handleRewardsBazaarClick}
                    {...LINK_PROPS}
                >
                    <div
                        css={styles.linkInner}
                        data-at={Sephora.debug.dataAt('rb_link_text')}
                    >
                        <div className='Link-target'>{localization.rewardsHeading}</div>
                        <div css={styles.desc}>{localization.rewardsDesc}</div>
                    </div>
                    <Flex
                        flexDirection='column'
                        alignItems='end'
                    >
                        <strong>{`${biPoints.toLocaleString()} `}</strong>
                        <span>
                            {localization.pointsLabel}
                            {biPoints !== 1 ? 's' : ''}
                        </span>
                    </Flex>
                </Link>
                {this.isCreditCardEnabled() && creditCardInfo && creditCardInfo.ccApprovalStatus === APPROVAL_STATUS.APPROVED ? (
                    <>
                        <Divider />
                        <Link
                            href='/profile/CreditCard'
                            onClick={this.handleCreditCardClick}
                            {...LINK_PROPS}
                        >
                            <div className='Link-target'>
                                {localization.ccHeading}
                                {bankRewards && (
                                    <>
                                        <b> • </b>
                                        <span>
                                            <strong>{`$${ccRewardsAmount} `}</strong>
                                            {localization.ccRewardsLabel}
                                        </span>
                                    </>
                                )}
                            </div>
                            <div
                                css={styles.desc}
                                className='Link-target'
                            >
                                {localization.ccDesc}
                            </div>
                        </Link>
                    </>
                ) : null}
                {Sephora.configurationSettings.isGamificationEnabled && (
                    <>
                        <Divider />
                        <Link
                            href='/beauty/challenges'
                            className='ActionableTarget'
                            onClick={this.handleBeautyChallengesClick}
                            {...LINK_PROPS}
                        >
                            <div css={styles.linkInner}>
                                <div className='Link-target'>
                                    <Flex>
                                        <p>{localization.beautyChallenges}</p>
                                        <Box marginLeft={2}>
                                            <Badge
                                                badge={localization.new}
                                                color={colors.black}
                                            />
                                        </Box>
                                    </Flex>
                                </div>
                                <div css={[styles.desc]}>{localization.beautyChallengesDescription}</div>
                            </div>
                        </Link>
                    </>
                )}
                <Divider thick={true} />
                <Link
                    href='/purchase-history'
                    onClick={this.handlePurchaseHistoryClick}
                    {...LINK_PROPS}
                >
                    <Image
                        src='/img/ufe/icons/subscription.svg'
                        {...ICON_PROPS}
                    />
                    <div css={styles.linkInner}>
                        <div className='Link-target'>{localization.purchasesHeading}</div>
                        <div css={styles.desc}>{localization.purchasesDesc}</div>
                        {showBuyItAgainImages && this.renderBuyItAgainImages()}
                    </div>
                </Link>
                <Divider />
                <Link
                    href='/profile/MyAccount/Orders'
                    onClick={this.handelOrdersClick}
                    {...LINK_PROPS}
                >
                    <Image
                        src='/img/ufe/icons/track.svg'
                        {...ICON_PROPS}
                    />
                    <div css={styles.linkInner}>
                        <div className='Link-target'>{localization.ordersHeading}</div>
                        <div css={styles.desc}>{localization.ordersDesc}</div>
                    </div>
                </Link>
                <Divider />
                <Link
                    href='/profile/MyAccount/AutoReplenishment'
                    onClick={this.handleAutoReplenishClick}
                    {...LINK_PROPS}
                >
                    <Image
                        src='/img/ufe/icons/auto-replenish.svg'
                        {...ICON_PROPS}
                    />
                    <div css={styles.linkInner}>
                        <div
                            className='Link-target'
                            children={localization.autoReplenishHeading}
                        />
                        <div
                            data-at={Sephora.debug.dataAt('auto-replenish-description')}
                            css={styles.desc}
                        >
                            {!isAutoReplenishEmptyHubEnabled || showAutoReplenishDescWithSubs
                                ? localization.autoReplenishDescWithSubs
                                : localization.autoReplenishDesc}
                        </div>
                    </div>
                </Link>
                <Divider />
                <Link
                    href='/shopping-list'
                    onClick={this.handleLovesClick}
                    {...LINK_PROPS}
                >
                    <Image
                        src='/img/ufe/icons/heart.svg'
                        {...ICON_PROPS}
                    />
                    <div css={styles.linkInner}>
                        <div className='Link-target'>{localization.lovesHeading}</div>
                        <div css={styles.desc}>{localization.lovesDesc}</div>
                    </div>
                </Link>
                <Divider />
                <Link
                    href='/profile/MyAccount'
                    onClick={this.handleAccountSettingsClick}
                    {...LINK_PROPS}
                >
                    <Image
                        src='/img/ufe/icons/account.svg'
                        {...ICON_PROPS}
                    />
                    <div css={styles.linkInner}>
                        <div className='Link-target'>{localization.accountHeading}</div>
                        <div css={styles.desc}>{localization.accountDesc}</div>
                    </div>
                </Link>
                {this.isCreditCardEnabled() &&
                (isAnonymous || !userUtils.isBI() || (creditCardInfo && creditCardInfo.ccApprovalStatus !== APPROVAL_STATUS.APPROVED)) ? (
                        <>
                            <Divider />
                            <Link
                                href='/profile/CreditCard'
                                onClick={this.handleCreditCardClick}
                                {...LINK_PROPS}
                            >
                                <Image
                                    src='/img/ufe/icons/cc-outline.svg'
                                    {...ICON_PROPS}
                                />
                                <div css={styles.linkInner}>
                                    <div className='Link-target'>{localization.ccHeading}</div>
                                    <div css={styles.desc}>{localization.ccApplyNow}</div>
                                </div>
                            </Link>
                        </>
                    ) : null}
                <Divider
                    color='nearWhite'
                    height={3}
                />
                <>
                    <Link
                        onClick={this.handleSameDayUnlimitedClick}
                        href={SDUUrl}
                        {...LINK_PROPS}
                    >
                        <Icon
                            name='bag'
                            {...ICON_PROPS}
                        />
                        <div css={styles.linkInner}>
                            <div className='Link-target'>{localization.sameDayUnlimitedHeading}</div>
                            <div css={styles.desc}>
                                {
                                    localization[
                                        isSDUMember
                                            ? 'sameDayUnlimitedMemberDesc'
                                            : isSDUNonMember
                                                ? 'subscribeToday'
                                                : 'sameDayUnlimitedNonMemberDesc'
                                    ]
                                }
                            </div>
                        </div>
                    </Link>
                    <Divider />
                </>
                <Link
                    onClick={this.handleOnTrackNavClick('reservations', '/happening/reservations')}
                    {...LINK_PROPS}
                >
                    <Image
                        src='/img/ufe/icons/reservations.svg'
                        {...ICON_PROPS}
                    />
                    <div css={styles.linkInner}>
                        <div className='Link-target'>{localization.reservationsHeading}</div>
                        <div css={styles.desc}>{localization.reservationsDesc}</div>
                    </div>
                </Link>
                <Divider />
                <Link
                    href='/in-store-services'
                    onClick={this.handleBeautyAdvisorRecommendationsClick}
                    {...LINK_PROPS}
                >
                    <Image
                        src='/img/ufe/icons/recommendations.svg'
                        {...ICON_PROPS}
                    />
                    <div css={styles.linkInner}>
                        <div className='Link-target'>{localization.recHeading}</div>
                        <div css={styles.desc}>{localization.recDesc}</div>
                    </div>
                </Link>
                {isAnonymous || (
                    <>
                        <Divider />
                        <Link
                            {...LINK_PROPS}
                            hoverSelector={null}
                            data-at={Sephora.debug.dataAt('sign_out_button')}
                            onClick={this.signOutHandler}
                        >
                            {localization.signOut}
                        </Link>
                    </>
                )}
            </>
        ) : null;
    };

    getFirstSkuImage = skuImages => {
        for (const key in skuImages) {
            if (Object.prototype.hasOwnProperty.call(skuImages, key)) {
                return skuImages[key];
            }
        }

        return null;
    };

    renderBuyItAgainImages = () => {
        const { purchaseHistory: propsPurchaseHistory } = this.props;
        const { purchaseHistory: statePurchaseHistory } = this.state;
        const purchaseHistory = propsPurchaseHistory || statePurchaseHistory;

        return (
            Array.isArray(purchaseHistory.items) && (
                <Flex flexDirection='row'>
                    {purchaseHistory.items.map(product => (
                        <Box key={product.sku.skuId}>
                            <Image
                                src={this.getFirstSkuImage(product.sku.skuImages)}
                                disableLazyLoad
                                {...BUY_IT_AGAIN_IMAGE_PROPS}
                            />
                        </Box>
                    ))}
                </Flex>
            )
        );
    };

    signOutHandler = () => {
        let redirectTo = window.location.href;

        // This should be read as: users that sign out while on Rich Profile pages,
        // should always get landed onto the homepage.
        if (
            (Sephora.pagePath && Sephora.pagePath.split('/')[1] === 'RichProfile' && Sephora.pagePath.split('/')[2] !== 'PurchaseHistory') ||
            Location.isRwdCreditCardPage() ||
            Location.isBIPage()
        ) {
            redirectTo = '/';
        }

        this.props.signOut(redirectTo);
        this.trackNavClick('sign out');
    };

    handleOnTrackNavClick = (navClickArg, targetUrl) => () => {
        this.trackNavClick(navClickArg);
        Location.navigateTo(null, targetUrl);
    };

    trackNavClick = link => {
        const { isBottomNav, localization } = this.props;
        const path = [isBottomNav ? 'bottom nav' : 'top nav', 'account', link];
        const analyticsData = {
            navigationInfo: anaUtils.buildNavPath(path)
        };

        if (link === BEAUTY_PREFERENCES) {
            analyticsData.isMySephoraPage = true;
        } else if (link === BEAUTY_CHALLENGES) {
            analyticsData.linkData = `gamification:flyout:${localization.beautyChallenges.toLowerCase()}`;
        }

        anaUtils.setNextPageData(analyticsData);
    };

    isCreditCardEnabled = () => {
        const { isAnonymous, creditCardInfo } = this.state;
        const isGlobalEnabled = Sephora.fantasticPlasticConfigurations.isGlobalEnabled;

        return (
            !localeUtils.isCanada() &&
            isGlobalEnabled &&
            (!userUtils.isBI() ||
                (creditCardInfo &&
                    creditCardInfo.ccApprovalStatus !== APPROVAL_STATUS.DECLINED &&
                    creditCardInfo.ccApprovalStatus !== APPROVAL_STATUS.CLOSED) ||
                isAnonymous)
        );
    };

    setUserInfoState = () => {
        const { user, auth } = this.props;

        this.setState({
            isUserReady: true,
            isAnonymous: userUtils.isAnonymous(auth),
            isBirthdayGiftEligible: userUtils.isBirthdayGiftEligible(user),
            creditCardInfo: userUtils.getSephoraCreditCardInfo(user)
        });
    };

    openSignInModal = () => {
        store.dispatch(
            this.props.showSignInModal({
                isOpen: true,
                callback: ({ tokens }) => {
                    this.props.updateProfileStatus({
                        profileSecurityStatus: [tokens.profileSecurityStatus],
                        accessToken: [tokens.accessToken, tokens.atExp],
                        refreshToken: [tokens.refreshToken, tokens.rtExp]
                    });
                    this.props.getUserFull();
                }
            })
        );
    };

    handlePurchaseHistoryFetch = showBuyItAgainLogic => {
        const { purchaseHistory, user } = this.props;
        const { showBuyItAgainImages, hasFetchedPurchaseHistory } = this.state;

        if (showBuyItAgainImages && !hasFetchedPurchaseHistory) {
            const purchaseHistoryCount = purchaseHistory?.items?.length || this.state.purchaseHistory?.items?.length || 0;

            if (purchaseHistoryCount < 1) {
                this.props.fetchPurchaseHistory(
                    { userId: user.profileId, requiredData: { purchaseHistory: {} } },
                    showBuyItAgainImages,
                    showBuyItAgainLogic
                );
                this.setState({ hasFetchedPurchaseHistory: true });
            }
        }
    };

    handlePurchaseHistoryUpdate = (purchaseHistory, prevPurchaseHistory) => {
        if (purchaseHistory && purchaseHistory !== prevPurchaseHistory && purchaseHistory.items && purchaseHistory.items.length > 0) {
            this.setState({ purchaseHistory: purchaseHistory });
        }
    };

    componentDidMount() {
        if (this.props.user?.isInitialized) {
            this.setUserInfoState();
            this.handlePurchaseHistoryFetch(this.props.showBuyItAgainLogic);
        }

        const onLastLoadEvent = Sephora.Util.onLastLoadEvent;

        onLastLoadEvent(window, [HydrationFinished], () => {
            window.addEventListener('promptUserToSignIn', () => {
                this.openSignInModal();
            });
        });

        store.setAndWatch('beautyInsider.biPersonalizedOffers', this, ({ biPersonalizedOffers }) => {
            this.setState({ biPersonalizedOffers });
        });
    }

    componentDidUpdate(prevProps) {
        const { user, purchaseHistory, showBuyItAgainLogic } = this.props;

        if (user && user !== prevProps.user && user.isInitialized) {
            this.setUserInfoState();
            this.handlePurchaseHistoryFetch(showBuyItAgainLogic);
        }

        this.handlePurchaseHistoryUpdate(purchaseHistory, prevProps.purchaseHistory);
    }

    componentWillUnmount() {
        window.removeEventListener('promptUserToSignIn', this.openSignInModal);
    }
}

AccountMenu.propTypes = {
    user: PropTypes.object.isRequired,
    localization: PropTypes.object.isRequired,
    profileCompletionPercentage: PropTypes.number.isRequired,
    onDismiss: PropTypes.func,
    isBottomNav: PropTypes.bool,
    showSignInModal: PropTypes.func.isRequired,
    showBiRegisterModal: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired,
    getUserFull: PropTypes.func.isRequired,
    dropWidth: PropTypes.number
};

AccountMenu.defaultProps = {
    onDismiss: null,
    isBottomNav: false,
    dropWidth: null
};

const styles = {
    desc: {
        fontSize: fontSizes.sm,
        color: colors.gray,
        marginTop: '.125em'
    },
    linkInner: {
        flex: 1,
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    pill: {
        display: 'inline-block',
        padding: '.25em .625em',
        marginTop: space[1],
        marginRight: 'auto',
        borderRadius: radii[2],
        backgroundColor: colors.lightBlue,
        fontSize: fontSizes.sm
    }
};

export default wrapComponent(AccountMenu, 'AccountMenu', true);
