/* eslint-disable class-methods-use-this */
import { ApolloLink, Observable } from '@apollo/client';
import LOCAL_STORAGE from 'utils/localStorage/Constants';
import Storage from 'utils/localStorage/Storage';
import stringUtils from 'utils/String';

const ERROR_TEXT = 'Unable to make GraphQL API call without a valid JWT token';
const AUTHRIZATION_HEADER_MASK = 'Bearer {0}';

class AuthenticationMiddleware extends ApolloLink {
    request(operation, next) {
        const jwt = Storage.local.getItem(LOCAL_STORAGE.AUTH_ACCESS_TOKEN);

        if (!jwt) {
            return new Observable(observer => observer.error(new Error(ERROR_TEXT)));
        }

        operation.setContext(({ headers = {} }) => ({
            headers: {
                ...headers,
                // https://jira.sephora.com/browse/INFL-5627
                authorization: stringUtils.format(AUTHRIZATION_HEADER_MASK, jwt)
            }
        }));

        return next(operation);
    }
}

export default AuthenticationMiddleware;
