/* eslint-disable class-methods-use-this */
import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { GraphQLException } from 'exceptions';
// prettier-ignore
import {
    AuthenticationMiddleware,
    CustomHeadersMiddleware,
    errorsMiddleware,
    FetchMiddleware,
    LoggerMiddleware
} from 'services/api/gql/middlewares';

class GraphQLClient extends ApolloClient {
    constructor() {
        super({
            // Even if we set the fetchPolicy to no-cache, we still need to provide a
            // cache instance to the ApolloClient because it is a required parameter.
            cache: new InMemoryCache(),
            name: 'webBrowserUFE',
            version: '1.0',
            defaultOptions: {
                mutate: { fetchPolicy: 'no-cache' },
                query: { fetchPolicy: 'no-cache' }
            },
            // prettier-ignore
            link: ApolloLink.from([
                errorsMiddleware,
                new LoggerMiddleware(),
                new AuthenticationMiddleware(),
                new CustomHeadersMiddleware(),
                new FetchMiddleware()
            ])
        });
    }

    async query(options) {
        try {
            const response = await super.query(options);
            const { data } = response;

            return data;
        } catch (exception) {
            // Catch any errors and rethrow as a GraphQLException
            throw new GraphQLException(exception);
        }
    }

    async mutate(options) {
        try {
            const response = await super.mutate(options);
            const { data } = response;

            return data;
        } catch (exception) {
            throw new GraphQLException(exception);
        }
    }
}

export const gqlClient = new GraphQLClient();
