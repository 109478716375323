import { onError } from '@apollo/client/link/error';
import stringUtils from 'utils/String';

const ERROR_MASK = '{0} [GraphQL] Error message: "{1}":';

export const errorsMiddleware = onError(response => {
    const { graphQLErrors, networkError } = response;

    if (graphQLErrors) {
        graphQLErrors.forEach(error => {
            const { message } = error;
            Sephora.logger.error(stringUtils.format(ERROR_MASK, performance.now(), message), error);
        });
    }

    if (networkError) {
        const { message } = networkError;
        Sephora.logger.error(stringUtils.format(ERROR_MASK, performance.now(), message), networkError);
    }

    // To do a retry API call logic use the function below.
    // return response.forward();
});
