import { HttpLink } from '@apollo/client';

// https://jira.sephora.com/browse/UA-1872
const SEPHORA_GRAPHQL_API_ENDPOINT = Sephora.configurationSettings.gqlAPIEndpoint;

class FetchMiddleware extends HttpLink {
    constructor() {
        super({ uri: SEPHORA_GRAPHQL_API_ENDPOINT });
    }
}

export default FetchMiddleware;
