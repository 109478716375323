/* eslint-disable class-methods-use-this */
import { ApolloLink } from '@apollo/client';
import { isUfeEnvLocal, isUfeEnvQA } from 'utils/Env';
import stringUtils from 'utils/String';

const OperationType = {
    Query: 'query',
    Mutation: 'mutation'
};
const OPERATION_START_MASK = '{0} [GraphQL] Start {1} request for {2}';
const OPERATION_END_MASK = '{0} [GraphQL] API execution time: {1}ms for operation {2}';
const DATA_DUMP_MASK = '{0} [GraphQL] Data dump for {1} {2}:';
const UNKNOWN_OPERATION_TYPE = 'Unsupported';
const UNKNOWN_OPERATION_NAME = 'Unnamed';

class LoggerMiddleware extends ApolloLink {
    request(operation, next) {
        if (isUfeEnvLocal || isUfeEnvQA || Sephora.logger.isVerbose) {
            const startTime = performance.now();
            let operationType = UNKNOWN_OPERATION_TYPE;
            const operationName = operation.operationName || UNKNOWN_OPERATION_NAME;

            if (operation.query) {
                operationType = OperationType.Query;
            } else if (operation.mutation) {
                operationType = OperationType.Mutation;
            }

            Sephora.logger.info(stringUtils.format(OPERATION_START_MASK, performance.now(), operationType, operationName));

            return next(operation).map(data => {
                const endTime = performance.now();
                const duration = endTime - startTime;
                Sephora.logger.info(stringUtils.format(DATA_DUMP_MASK, performance.now(), operationType, operationName), data);
                Sephora.logger.info(stringUtils.format(OPERATION_END_MASK, performance.now(), duration, operationName));

                return data;
            });
        } else {
            return next(operation);
        }
    }
}

export default LoggerMiddleware;
