export default function getResource(label, vars = []) {
    const resources = {
        ifYouOrderWithin: `If you ${vars[0]}`,
        free: 'GRATUIT',
        warning: 'Avertissement',
        shippingMethodError: `Malheureusement, nous ne pouvons effectuer l'expédition à votre adresse que par l'entremise de ${vars[0]}. Veuillez sélectionner cette méthode ou choisir une autre adresse sur la feuille Apple Pay.`,
        error: 'Error',
        adjustBasket: 'Adjust Basket',
        verificationRequired: 'Verification Required',
        contactCS: `Account verification is required to complete purchase. Please Chat with or call Customer Service at ${vars[0]}.`,
        csPhone: '1-877-SEPHORA (1-877-737-4672)',
        csHours: 'Customer Service is available:',
        monToFri: 'MON-FRI',
        monFriHours: `${vars[0]}: 5am - 9pm PT`,
        satToSun: 'SAT-SUN',
        satSunHours: `${vars[0]}: 6am - 9pm PT`,
        ok: 'OK'
    };

    return resources[label];
}
