import anaConsts from 'analytics/constants';
import processEvent from 'analytics/processEvent';

const { ASYNC_PAGE_LOAD, LINK_TRACKING_EVENT } = anaConsts;

class RewardFulfillmentMethodModalBindings {
    static triggerAnalytics = (type, analyticsData) => {
        const payload = {
            data: { ...analyticsData }
        };
        processEvent.process(type, payload);
    };

    static pageLoad = () => {
        const anaData = {
            pageName: anaConsts.PAGE_NAMES.REWARD_FULFILLMENT_METHOD_MODAL
        };

        RewardFulfillmentMethodModalBindings.triggerAnalytics(ASYNC_PAGE_LOAD, anaData);
    };

    static confirmMethodModal = ({ method, skuId }) => {
        const productStrings = () => {
            return `;${skuId};;;;eVar26=${skuId}`;
        };

        const eventData = {
            productStrings: productStrings(),
            actionInfo: `choose method:${method}` //prop55
        };

        RewardFulfillmentMethodModalBindings.triggerAnalytics(LINK_TRACKING_EVENT, eventData);
    };
}

export default RewardFulfillmentMethodModalBindings;
