import ufeApi from 'services/api/ufeApi';
import biProfile from 'utils/BiProfile';
import userUtils from 'utils/User';

// https://jira.sephora.com/wiki/display/ILLUMINATE/Get+BI+Points+API

function getBiPoints(userProfileId, source = 'profile', orderId) {
    const { useLXSBiPoints } = Sephora.configurationSettings;
    const headers = useLXSBiPoints
        ? {
            'x-requested-source': 'web'
        }
        : undefined;
    const biAccountId = userUtils.getBiAccountId();

    let url = useLXSBiPoints ? `/gway/v1/lxs/${biAccountId}/points?source=${source}` : `/api/bi/profiles/${userProfileId}/points?source=${source}`;

    if (source === biProfile.SOURCES.ORDER_CONFIRMATION) {
        url += `${useLXSBiPoints ? `&orderId=${orderId}` : ''}&isLastOrder=true`;
    }

    return ufeApi.makeRequest(url, { method: 'GET', headers }).then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default getBiPoints;
