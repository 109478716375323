import { gqlClient } from 'services/api/gql/gqlClient';
import { CustomerLimitQuery } from 'constants/gql/queries/profile/customerLimit.gql';

const customerLimitQuery = async (loyaltyId, currency) => {
    // Pass loyaltyId as part of the "request" object as per the GraphQL query requirement
    const options = {
        query: CustomerLimitQuery,
        variables: { request: { loyaltyId, currency } } // Wrap loyaltyId in the "request" object
    };

    // Send the query to the GraphQL client
    // If error is thrown, it will be handled in ProfileActions.js catch block.
    const { customerLimit } = await gqlClient.query(options);

    // Return the customer limit or null if not found
    return customerLimit || null;
};

export default customerLimitQuery;
