import ufeApi from 'services/api/ufeApi';
import headerUtils from 'utils/Headers';
import RCPSCookies from 'utils/RCPSCookies';
import authUtils from 'utils/Authentication';
import NGPCookies from 'utils/NGPCookies';

const { userXTimestampHeader } = headerUtils;

// https://jira.sephora.com/wiki/display/ILLUMINATE/Create+User+API

const ATG_URL = '/api/users/profile';
const SDN_URL = '/gway/v1/dotcom/auth/v1/user/register';

export async function createUser(profileData) {
    const isNGPUserRegistrationEnabled = NGPCookies.isNGPUserRegistrationEnabled();
    const url = isNGPUserRegistrationEnabled ? SDN_URL : ATG_URL;
    const { headerValue, inStoreUser, biAccountId, ...args } = profileData;

    if (isNGPUserRegistrationEnabled && inStoreUser) {
        args.isStoreRegistration = inStoreUser;
        args.biId = biAccountId;
    }

    delete args.inStoreUser;

    const headers = {
        'x-requested-source': 'web',
        'Content-Type': 'application/json',
        ...(headerValue && { headerValue }),
        ...userXTimestampHeader()
    };

    if (RCPSCookies.isRCPSAuthEnabled()) {
        headers.deviceId = await authUtils.getFingerPrint();
    }

    return ufeApi
        .makeRequest(url, {
            method: 'POST',
            headers,
            body: JSON.stringify(args)
        })
        .then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default { createUser };
